import React, { useState, useEffect } from "react";
import axios from "axios";
import * as moment from "moment";
import { isEmpty, trim, find } from "lodash";
//
import Loader from "./components/Loader";
//
import "./App.css";
import "./global.css";

export default function App() {
  const [tableData, setTableData] = useState([]);
  //
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState("");
  const [courtsArr, setCourtsArr] = useState(
    [
      { sigla: "CJF", nome: "CJF - Conselho da Justiça Federal" },
      { sigla: "CNJ", nome: "CNJ - Conselho Nacional de Justiça" },
      { sigla: "CSJT", nome: "CSJT - Conselho Superior da Justiça do Trabalho" },
      { sigla: "PJeCor", nome: "PJeCor - Corregedorias" },
      { sigla: "SEEU", nome: "Sistema Eletrônico de Execução Unificado" },
      { sigla: "STF", nome: "STF - Supremo Tribunal Federal" },
      { sigla: "STJ", nome: "STJ - Superior Tribunal de Justiça" },
      { sigla: "STM", nome: "STM - Superior Tribunal Militar" },
      { sigla: "TJAC", nome: "TJAC - Tribunal de Justiça do Acre" },
      { sigla: "TJAL", nome: "TJAL - Tribunal de Justiça de Alagoas" },
      { sigla: "TJAM", nome: "TJAM - Tribunal de Justiça do Amazonas" },
      { sigla: "TJAP", nome: "TJAP - Tribunal de Justiça do Amapá" },
      { sigla: "TJBA", nome: "TJBA - Tribunal de Justiça da Bahia" },
      { sigla: "TJCE", nome: "TJCE - Tribunal de Justiça do Ceará" },
      { sigla: "TJDFT", nome: "TJDFT - Tribunal de Justiça do Distrito Federal e Territórios" },
      { sigla: "TJES", nome: "TJES - Tribunal de Justiça do Espírito Santo" },
      { sigla: "TJGO", nome: "TJGO - Tribunal de Justiça de Goiás" },
      { sigla: "TJMA", nome: "TJMA - Tribunal de Justiça do Maranhão" },
      { sigla: "TJMG", nome: "TJMG - Tribunal de Justiça de Minas Gerais" },
      { sigla: "TJMMG", nome: "TJMMG - Tribunal de Justiça Militar do Estado de Minas Gerais" },
      { sigla: "TJMRS", nome: "TJMRS - Tribunal de Justiça Militar do Estado do Rio Grande do sul" },
      { sigla: "TJMS", nome: "TJMS - Tribunal de Justiça do Mato Grosso do Sul" },
      { sigla: "TJMSP", nome: "TJMSP - Tribunal de Justiça Militar do Estado de São Paulo" },
      { sigla: "TJMT", nome: "TJMT - Tribunal de Justiça do Mato Grosso" },
      { sigla: "TJPA", nome: "TJPA - Tribunal de Justiça do Pará" },
      { sigla: "TJPB", nome: "TJPB - Tribunal de Justiça da Paraíba" },
      { sigla: "TJPE", nome: "TJPE - Tribunal de Justiça de Pernambuco" },
      { sigla: "TJPI", nome: "TJPI - Tribunal de Justiça do Piauí" },
      { sigla: "TJPR", nome: "TJPR - Tribunal de Justiça do Paraná" },
      { sigla: "TJRJ", nome: "TJRJ - Tribunal de Justiça do Rio de Janeiro" },
      { sigla: "TJRN", nome: "TJRN - Tribunal de Justiça do Rio Grande do Norte" },
      { sigla: "TJRO", nome: "TJRO - Tribunal de Justiça de Rondônia" },
      { sigla: "TJRR", nome: "TJRR - Tribunal de Justiça de Roraima" },
      { sigla: "TJRS", nome: "TJRS - Tribunal de Justiça do Rio Grande do Sul" },
      { sigla: "TJSC", nome: "TJSC - Tribunal de Justiça de Santa Catarina" },
      { sigla: "TJSE", nome: "TJSE - Tribunal de Justiça de Sergipe" },
      { sigla: "TJSP", nome: "TJSP - Tribunal de Justiça de São Paulo" },
      { sigla: "TJTO", nome: "TJTO - Tribunal de Justiça do Estado de Tocantins" },
      { sigla: "TRE-AC", nome: "TRE-AC - Tribunal Regional Eleitoral do Acre" },
      { sigla: "TRE-AL", nome: "TRE-AL - Tribunal Regional Eleitoral de Alagoas" },
      { sigla: "TRE-AM", nome: "TRE-AM - Tribunal Regional Eleitoral do Amazonas" },
      { sigla: "TRE-AP", nome: "TRE-AP - Tribunal Regional Eleitoral do Amapá" },
      { sigla: "TRE-BA", nome: "TRE-BA - Tribunal Regional Eleitoral da Bahia" },
      { sigla: "TRE-CE", nome: "TRE-CE - Tribunal Regional Eleitoral do Ceará" },
      { sigla: "TRE-DF", nome: "TRE-DF - Tribunal Regional Eleitoral do Distrito Federal e Territórios" },
      { sigla: "TRE-ES", nome: "TRE-ES - Tribunal Regional Eleitoral do Espírito Santo" },
      { sigla: "TRE-GO", nome: "TRE-GO - Tribunal Regional Eleitoral de Goiás" },
      { sigla: "TRE-MA", nome: "TRE-MA - Tribunal Regional Eleitoral do Maranhão" },
      { sigla: "TRE-MG", nome: "TRE-MG - Tribunal Regional Eleitoral de Minas Gerais" },
      { sigla: "TRE-MS", nome: "TRE-MS - Tribunal Regional Eleitoral do Mato Grosso do Sul" },
      { sigla: "TRE-MT", nome: "TRE-MT - Tribunal Regional Eleitoral do do Mato Grosso" },
      { sigla: "TRE-PA", nome: "TRE-PA - Tribunal Regional Eleitoral do do Pará" },
      { sigla: "TRE-PB", nome: "TRE-PB - Tribunal Regional Eleitoral da Paraíba" },
      { sigla: "TRE-PE", nome: "TRE-PE - Tribunal Regional Eleitoral de Pernambuco" },
      { sigla: "TRE-PI", nome: "TRE-PI - Tribunal Regional Eleitoral do Piauí" },
      { sigla: "TRE-PR", nome: "TRE-PR - Tribunal Regional Eleitoral do Paraná" },
      { sigla: "TRE-RJ", nome: "TRE-RJ - Tribunal Regional Eleitoral do Rio de Janeiro" },
      { sigla: "TRE-RN", nome: "TRE-RN - Tribunal Regional Eleitoral do Rio Grande do Norte" },
      { sigla: "TRE-RO", nome: "TRE-RO - Tribunal Regional Eleitoral de Rondônia" },
      { sigla: "TRE-RR", nome: "TRE-RR - Tribunal Regional Eleitoral de Roraima" },
      { sigla: "TRE-RS", nome: "TRE-RS - Tribunal Regional Eleitoral do Rio Grande do Sul" },
      { sigla: "TRE-SC", nome: "TRE-SC - Tribunal Regional Eleitoral de Santa Catarina" },
      { sigla: "TRE-SE", nome: "TRE-SE - Tribunal Regional Eleitoral de Sergipe" },
      { sigla: "TRE-SP", nome: "TRE-SP - Tribunal Regional Eleitoral de São Paulo" },
      { sigla: "TRE-TO", nome: "TRE-TO - Tribunal Regional Eleitoral de Tocantins" },
      { sigla: "TRF1", nome: "TRF1 - Tribunal Regional Federal da 1ª Região" },
      { sigla: "TRF2", nome: "TRF2 - Tribunal Regional Federal da 2ª Região" },
      { sigla: "TRF3", nome: "TRF3 - Tribunal Regional Federal da 3ª Região" },
      { sigla: "TRF4", nome: "TRF4 - Tribunal Regional Federal da 4ª Região" },
      { sigla: "TRF5", nome: "TRF5 - Tribunal Regional Federal da 5ª Região" },
      { sigla: "TRF6", nome: "TRF6 - Tribunal Regional Federal da 6ª Região" },
      { sigla: "TRT1", nome: "TRT1 - Tribunal Regional do Trabalho da 1ª Região" },
      { sigla: "TRT10", nome: "TRT10 - Tribunal Regional do Trabalho da 10ª Região" },
      { sigla: "TRT11", nome: "TRT11 - Tribunal Regional do Trabalho da 11ª Região" },
      { sigla: "TRT12", nome: "TRT12 - Tribunal Regional do Trabalho da 12ª Região" },
      { sigla: "TRT13", nome: "TRT13 - Tribunal Regional do Trabalho da 13ª Região" },
      { sigla: "TRT14", nome: "TRT14 - Tribunal Regional do Trabalho da 14ª Região" },
      { sigla: "TRT15", nome: "TRT15 - Tribunal Regional do Trabalho da 15ª Região" },
      { sigla: "TRT16", nome: "TRT16 - Tribunal Regional do Trabalho da 16ª Região" },
      { sigla: "TRT17", nome: "TRT17 - Tribunal Regional do Trabalho da 17ª Região" },
      { sigla: "TRT18", nome: "TRT18 - Tribunal Regional do Trabalho da 18ª Região" },
      { sigla: "TRT19", nome: "TRT19 - Tribunal Regional do Trabalho da 19ª Região" },
      { sigla: "TRT2", nome: "TRT2 - Tribunal Regional do Trabalho da 2ª Região" },
      { sigla: "TRT20", nome: "TRT20 - Tribunal Regional do Trabalho da 20ª Região" },
      { sigla: "TRT21", nome: "TRT21 - Tribunal Regional do Trabalho da 21ª Região" },
      { sigla: "TRT22", nome: "TRT22 - Tribunal Regional do Trabalho da 22ª Região" },
      { sigla: "TRT23", nome: "TRT23 - Tribunal Regional do Trabalho da 23ª Região" },
      { sigla: "TRT3", nome: "TRT3 - Tribunal Regional do Trabalho da 3ª Região" },
      { sigla: "TRT4", nome: "TRT4 - Tribunal Regional do Trabalho da 4ª Região" },
      { sigla: "TRT5", nome: "TRT5 - Tribunal Regional do Trabalho da 5ª Região" },
      { sigla: "TRT6", nome: "TRT6 - Tribunal Regional do Trabalho da 6ª Região" },
      { sigla: "TRT7", nome: "TRT7 - Tribunal Regional do Trabalho da 7ª Região" },
      { sigla: "TRT8", nome: "TRT8 - Tribunal Regional do Trabalho da 8ª Região" },
      { sigla: "TRT9", nome: "TRT9 - Tribunal Regional do Trabalho da 9ª Região" },
      { sigla: "TSE", nome: "TSE - Tribunal Superior Eleitoral" },
      { sigla: "TST", nome: "TST - Tribunal Superior do Trabalho" }
    ]
  );
  const [filters, setFilters] = useState("");
  const [court, setCourt] = useState("");
  const [totalCounter, setTotalCounter] = useState(0);
  const [dataStart, setDataStart] = useState();
  const [page, setPage] = useState("1");
  const [itemsPerPage, setItemsPerPage] = useState("50");
  //
  const [currentCourt, setCurrentCourt] = useState(0);

  useEffect(() => {
    if (court) {
      CreaterFilter();
    }
  }, [court]);
  //
  async function handleSearch(e) {
    e.preventDefault();
    CreaterFilter();
  }
  //
  const CreaterFilter = () => {
    StringifyFilter({
      court,
      dataStart,
      page,
      itemsPerPage,
    });
  };
  //
  const StringifyFilter = (filterData) => {
    console.log(filterData);
    const { court, dataStart } = filterData;
    if (isEmpty(court) || isEmpty(dataStart)) {
      setFilters("");
      return "";
    }
    let filter = "?";
    let fCourt = "";
    let fDataStart = "";
    let fDataEnd = "";
    //
    fCourt = court ? `siglaTribunal=${trim(court)}&` : "";
    fDataStart = dataStart
      ? `dataDisponibilizacaoInicio=${trim(dataStart)}&`
      : "";
    fDataEnd = dataStart
      ? `dataDisponibilizacaoFim=${trim(dataStart)}&`
      : "";

    filter += fCourt + fDataStart + fDataEnd;
    filter = filter.substring(0, filter.lastIndexOf("&"));
    setFilters(filter);
  };
  //
  let dataA = [];
  let pageA = 1;
  const GetData = () => {
    setLoading(true);
    setTableData([]);
    const pagination = `pagina=${pageA}&itensPorPagina=${itemsPerPage}`;
    const _filter = !isEmpty(filters)
      ? filters + "&" + pagination
      : "?" + pagination;
    //
    axios
      .get(`https://comunicaapi.pje.jus.br/api/v1/comunicacao${_filter}`)
      .then(function (response) {
        const { data } = response;
        setTotalCounter(data.count);
        //
        if (data.status === "success") {
          if (data.count > 0) {
            const pagination = `Pag: ${pageA} - SubTotal: ${dataA.length} - Total: ${data.count} - Sigla: ${court}`;
            dataA = dataA.concat(data.items);
            console.log(pagination);
            //
            setLoadingData(pagination);
            pageA++;
            //
            if (dataA.length < data.count) {
              GetData();
            } else {
              setTableData(dataA);
              setLoadingData(pagination);
              console.log(
                "TableData: ",
                !isEmpty(dataA) && dataA.length
              );
              dataA.length > 0 && PrintData(dataA);
              //
              setLoading(false);
              //
              if (courtsArr.length !== currentCourt.length) {
                setCurrentCourt(currentCourt + 1);
              }
            }
          } else {
            setLoadingData("Nenhum registro encontrado");
            setLoading(false);
          }
        }
      })
      .catch(function (error) {
        console.log("--------- ERROR ---------");
        setLoading(false);
        if (error.response) {
          // Request made and server responded
          console.log("--- RESPONSE ---");
          const { data } = error.response;
          console.log(`Status: ${data.status}`);
          console.log(`Title: ${data.title}`);
        } else if (error.request) {
          // The request was made but no response was received
          console.log("--- REQUENT ---");
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("--- MESSAGE ---");
          console.log("Error", error.message);
        }
        console.log("------------------------------------");
        setTimeout(() => {
          console.log("+++ TRY AGAIN +++");
          GetData();
        }, 1000);
      });
  };
  //
  const PrintData = async (dataA) => {
    let count = 0;
    let values = "";
    setLoading(true);

    values += `${find(courtsArr, ['sigla', court])?.nome}`;
    values += "\n";
    values += `${moment(dataStart).format("DD/MM/YYYY")}`;
    values += "\n";
    values += "Total de Registro de Processos: " + { totalCounter };
    values += "\n";
    values += "\n";
    values += "\n";
    //
    !isEmpty(dataA) &&
      dataA.forEach((item, index) => {
        count++;
        values += ";;Publicação";
        values += "\n";
        values += `Processo: ${item.numeroprocessocommascara}\n`;
        values += `Instituição: ${item.siglaTribunal}\n`;
        values += `Órgão: ${item.nomeOrgao}\n`;
        values += `Data de disponibilização: ${item.data_disponibilizacao}\n`;
        values += `Tipo de comunicação: ${item.tipoComunicacao}\n`;
        values += `Meio: ${item.meiocompleto}\n`;

        values += `Partes: ${item.destinatarios
          .map((part) => {
            return part.nome;
          })
          .join(", ")}\n`;

        values += `Advogados: ${item.destinatarioadvogados
          .map((dest) => {
            return dest.advogado.nome;
          })
          .join(", ")}
					\n`;
        values += "\n";
        values += "Conteúdo: " + item.texto;
        values += "\n";
        values += "\n";
        values += "\n";
      });

    if (count === dataA.length) {
      // console.log(values);
      setLoading(false);
      return true;
    }
  };
  //
  useEffect(() => {
    // console.log(filters, page);
    if (!isEmpty(filters)) {
      GetData();
    }
    // GetCourts();
  }, [filters]);
  //
  // INSTITUIÇÃO
  const handleChangeCourts = (e) => {
    setCourt(e.target.value);
  };
  //
  // DATA START
  const handleChangeDataStart = (e) => {
    setDataStart(e.target.value);
  };
  //
  return (
    <>
      <Loader active={loading} />
      <form onSubmit={handleSearch} style={{ marginBottom: "30px" }}>
        <select
          name="courts"
          id="courts"
          onChange={(e) => handleChangeCourts(e)}
        >
          <option value="-">Selecione uma instituição</option>
          {courtsArr.map((item, index) => [
            <option value={item.sigla} key={item.sigla}>
              [ {item.sigla} ] - {item.nome}
            </option>,
          ])}
        </select>
        <input
          style={{ margin: "0 16px" }}
          placeholder="Data inicial"
          type="date"
          id="dataStart"
          name="dataStart"
          value={dataStart}
          onChange={(e) => handleChangeDataStart(e)}
        />
        <button className="button" type="submit">
          Buscar
        </button>
      </form>
      <hr />
      {!isEmpty(tableData) ? (
        <>
          <div style={{ padding: "30px 0", borderTop: "1p solid" }}>
            <div>{find(courtsArr, ['sigla', court])?.nome}</div>
            <div style={{ padding: "20px 0" }}>
              {moment(dataStart).format("DD/MM/YYYY")}
            </div>
            <b>Total de Registro de Processos</b>: {totalCounter}
          </div>
          {tableData.map((item, index) => {
            return (
              <div key={index}>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>;;Publicação</div>
                <div className="cardHeader">{`Processo: ${item.numeroprocessocommascara} - Instituição: ${item.siglaTribunal} - Órgão: ${item.nomeOrgao} - Data de disponibilização: ${item.data_disponibilizacao} - Tipo de comunicação: ${item.tipoComunicacao} - Meio: ${item.meiocompleto}
						`}</div>
                <div>&nbsp;</div>
                <div className="cardSubHeader">
                  <div>
                    Partes:{" "}
                    {item?.destinatarios
                      .map((part) => {
                        return part.nome;
                      })
                      .join(", ")}{" "}
                  </div>
                  <div>
                    Advogados:{" "}
                    {item?.destinatarioadvogados
                      .map((dest) => {
                        return dest.advogado.nome;
                      })
                      .join(", ")}
                  </div>
                </div>
                <div>&nbsp;</div>
                <div className="cardBody">{item.texto}</div>
              </div>
            );
          })}
        </>
      ) : (
        <div style={{ textAlign: "center", fontSize: "16px" }}>
          {loadingData}
        </div>
      )}
    </>
  );
}
